import { AudurContentPageEditorialContentGroup } from '@kvika/audur-prismic-types';
import styles from './BannerCarousel.module.scss';
import CarouselTab from './CarouselTab';

type Props = {
  banners: AudurContentPageEditorialContentGroup[];
  selectedBannerId: number;
  accentColor: string;
  onChangeBanner(index: number): void;
  className: string;
};

const CarouselTabs = ({ banners, selectedBannerId, accentColor, onChangeBanner, className }: Props) => {
  return (
    <div className={className}>
      {banners.map((_, index) => {
        return (
          <CarouselTab
            className={`${styles.line} ${selectedBannerId === index ? styles.selected : ''}`}
            accentColor={accentColor}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => onChangeBanner(index)}
          />
        );
      })}
    </div>
  );
};

export default CarouselTabs;
