import * as React from 'react';
import styles from './BannerCarousel.module.scss';

type Props = {
  onClick(): void;
  className: string;
  accentColor: string;
};

const newStyles = {
  setBackgroundColor(color: string) {
    return { backgroundColor: color };
  },
};

const CarouselTab = ({ onClick, className, accentColor }: Props) => {
  return (
    <button className={styles.tabWrapper} onClick={onClick}>
      {/* accentColor check made to avoid rendering mismatch SSR and Client */}
      {accentColor && (
        <div className={`${styles.tab} ${className}`} style={newStyles.setBackgroundColor(accentColor)} />
      )}
    </button>
  );
};

export default CarouselTab;
