import { useRouter } from 'next/router';
import { GetStaticProps } from 'next';
import { useState, useMemo } from 'react';
import {
  AudurContentPage,
  AudurContentPageEditorialContentGroup,
  AudurFooter,
  AudurHeader,
  AudurMarketInterestRatesConnectionConnection,
  FaqCategoryConnectionConnection,
  Maybe,
} from '@kvika/audur-prismic-types';
import { Colors, Spacing } from '@kvika/audur-theme';
import { AudurPrismicClient } from '@kvika/audur-prismic-client';
import { valueOrDefault } from '@kvika/audur-prismic-utils';
import { asText } from '@prismicio/helpers';
import { useViewportScroll } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

import { ProductSchema } from '@kvika/audur-api-types';
import Layout from '../components/Layout';
import Calculator from '../components/calculator/Calculator';
import BannerCarousel from '../components/banner-carousel/BannerCarousel';
import AudurWelcomeContent from '../components/content-blocks/AudurWelcomeContent';
import FAQTab from '../components/faq/FaqTab';
import { MainGridContainer, GridItem } from '../components/grid-container';
import { TranslatedStrings } from '../data/TranslatedStrings';
import { ColumnClass, Gutters, MetaTagInfo, PreviewData } from '../types/Types';
import { Route } from '../navigation/Routes';
import { executeScrollToElement, getAudurApiClient, getAudurFooter, getAudurHeader, getMetaTags } from '../utils/Utils';
import { ANSWERS_CONTAINER_ID, prismicConstants } from '../utils/Constants';

type Props = {
  allAudurProducts: ProductSchema[];
  allAudurMarketInterestRates: Maybe<AudurMarketInterestRatesConnectionConnection>;
  allFAQCategories: Maybe<FaqCategoryConnectionConnection>;
  audurContentPage: Maybe<AudurContentPage>;
  metaTags: MetaTagInfo;
  footer: AudurFooter;
  header: AudurHeader;
};

const Home = ({
  allAudurProducts,
  allAudurMarketInterestRates,
  allFAQCategories,
  audurContentPage,
  metaTags,
  footer,
  header,
}: Props) => {
  const router = useRouter();
  const { home, faq } = TranslatedStrings.is;
  const [isLottiePaused, setLottiePaused] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const navigateToFAQ = (tab: string) => {
    router
      .push({
        pathname: `${Route.Faq}/${tab}`,
      })
      .then(() => executeScrollToElement(ANSWERS_CONTAINER_ID));
  };
  const minLottieImageY = 0.28;
  const { scrollYProgress } = useViewportScroll();

  useMemo(() => {
    return scrollYProgress.onChange((y) => {
      setLottiePaused(y > minLottieImageY);
    });
  }, [scrollYProgress]);

  return (
    <Layout title={home.title} metaTags={metaTags} footer={footer} header={header}>
      <MainGridContainer
        backgroundColor={Colors.DarkGray}
        gutters={Gutters.LeftOnly}
        style={{ paddingBottom: 4, height: '620px' }}
      >
        <BannerCarousel
          groups={
            audurContentPage?.editorialContentGroup?.map((group) => {
              return group as AudurContentPageEditorialContentGroup;
            }) ?? []
          }
          isLottiePaused={isLottiePaused}
          isMobile={isMobile}
          isTablet={isTablet}
          color={Colors}
          valueOrDefault={valueOrDefault}
        />
      </MainGridContainer>
      <MainGridContainer backgroundColor={Colors.Beige} gutters={Gutters.Both} style={{ paddingBottom: 4 }}>
        <GridItem
          className={ColumnClass.All}
          style={{
            justifyContent: 'center',
            paddingTop: isMobile ? Spacing.Medium : Spacing.XLarge,
            paddingBottom: Spacing.XLarge,
          }}
        >
          {allAudurProducts && allAudurMarketInterestRates ? (
            <Calculator allAudurProducts={allAudurProducts} allAudurMarketInterestRates={allAudurMarketInterestRates} />
          ) : null}
        </GridItem>
      </MainGridContainer>
      <MainGridContainer
        backgroundColor={Colors.BabyBlue}
        gutters={Gutters.Both}
        style={{ paddingTop: 32, paddingBottom: 64 }}
      >
        <>
          {allFAQCategories?.edges?.map((maybeEdge, index) => {
            const categoryTitle = asText(maybeEdge?.node.title);
            const categoryImgSrc = maybeEdge?.node.image.url ?? '';
            if (categoryTitle) {
              return (
                <FAQTab
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  tabTitle={maybeEdge?.node._meta.uid ?? ''}
                  text={categoryTitle}
                  imgSrc={categoryImgSrc}
                  handleSetSelectedTab={() => navigateToFAQ(maybeEdge?.node._meta.uid ?? '')}
                />
              );
            }
            return null;
          })}
          <FAQTab
            tabTitle="hafa-samband"
            text={faq.contact}
            imgSrc="/svg/hafa-samband.svg"
            handleSetSelectedTab={() => router.push(Route.Contact)}
          />
        </>
      </MainGridContainer>
      <AudurWelcomeContent />
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async ({ previewData, locale }) => {
  const audurClient = new AudurPrismicClient(prismicConstants.apiToken);
  const uId = 'forsida';

  const allAudurMarketInterestRates = await audurClient.getMarketInterestRates({
    variables: { lang: prismicConstants.apiDefaultLocale },
  });

  const allFAQCategories = await audurClient.getAllFAQCategories({
    variables: {
      lang: prismicConstants.apiDefaultLocale,
    },
  });

  const audurContentPage = await audurClient.getAudurContentPageByUid({
    previewData: previewData as PreviewData,
    variables: { uId, lang: locale },
  });

  const footer = await getAudurFooter();
  const header = await getAudurHeader();

  const metaTags = getMetaTags({
    ...audurContentPage,
    __typename: 'AudurContentPage',
  } as Maybe<AudurContentPage>);

  const apiClient = getAudurApiClient();

  return {
    props: {
      allAudurProducts: await apiClient.getProducts(),
      allAudurMarketInterestRates,
      allFAQCategories,
      audurContentPage,
      metaTags,
      footer,
      header,
    },
    revalidate: 24 * 60 * 60, // only cache static page for a single day
  };
};
