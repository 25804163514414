import Image from 'next/image';
import { GridItem } from '../grid-container';
import styles from './FaqTab.module.scss';

type Props = {
  tabTitle: string;
  text: string;
  imgSrc: string;
  handleSetSelectedTab(tabTitle: string): void;
};

const DESKTOP_IMAGE_SIZE = 174;
const MOBILE_IMAGE_SIZE = 64;

const FAQTab = ({ tabTitle, text, imgSrc, handleSetSelectedTab }: Props) => {
  return (
    <GridItem className={styles.faqGridItem}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={styles.container} onClick={() => handleSetSelectedTab(tabTitle)}>
        <div className={styles.image}>
          <Image
            src={imgSrc}
            alt={text}
            fill
            sizes={`(max-width: 599px) ${MOBILE_IMAGE_SIZE}px, ${DESKTOP_IMAGE_SIZE}px`}
            priority
          />
        </div>
        <span className={styles.title}>{text}</span>
      </div>
    </GridItem>
  );
};

export default FAQTab;
