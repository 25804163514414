import React, { FunctionComponent } from 'react';

import NumberFormat from 'react-number-format';

import styles from './CalculatorInput.module.scss';

type Props = {
  value?: number;
  suffix?: string;
  onChange(newValue?: number): void;
  max: number;
};

const CalculatorInput: FunctionComponent<Props> = ({ value, suffix, onChange, max }) => {
  return (
    <NumberFormat
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={0} // don't allow decimals
      allowNegative={false}
      allowEmptyFormatting // display "kr." when empty
      suffix={suffix}
      value={value}
      onValueChange={(values) => onChange(values.floatValue)}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue === undefined) {
          return true;
        }
        return floatValue >= 0 && floatValue <= max;
      }}
      type="tel" // use number keyboard in mobile
      className={styles.numberFormatInput}
    />
  );
};

export default CalculatorInput;
