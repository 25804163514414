import * as React from 'react';
import lottie, { AnimationItem } from 'lottie-web/build/player/lottie_light';
import { Colors } from '@kvika/audur-theme';

type Props = {
  loop?: boolean;
  paused?: boolean;
  style?: React.CSSProperties;
  lottieClassName?: string;
  height: number;
  imageOffset: {
    x: number;
    y: number;
  };
  imagePath: string;
};

const LottieCard = ({ loop = false, paused, style, height, imageOffset, imagePath, lottieClassName }: Props) => {
  const lottieRef = React.useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = React.useState<AnimationItem | null>(null);

  React.useEffect(() => {
    animation?.setSubframe(false);
  }, [animation]);

  React.useEffect(() => {
    if (paused) {
      animation?.pause();
    } else {
      animation?.play();
    }
  }, [animation, paused]);

  React.useEffect(() => {
    if (lottieRef && lottieRef.current) {
      lottie.destroy();
      const animation = lottie.loadAnimation({
        container: lottieRef.current,
        renderer: 'svg',
        loop,
        autoplay: true,
        path: imagePath,
      });
      setAnimation(animation);
    }
  }, [imagePath, loop]);

  return (
    <div
      style={{
        marginBottom: imageOffset.y,
        backgroundColor: Colors.Transparent,
        ...style,
      }}
      suppressHydrationWarning
    >
      <div style={{ height }} ref={lottieRef} className={lottieClassName} suppressHydrationWarning />
    </div>
  );
};

export default LottieCard;
