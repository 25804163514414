import React, { CSSProperties, FunctionComponent } from 'react';

import { toPercentageString } from '@kvika/string-utils';
import { Colors, Spacing } from '@kvika/audur-theme';
import { getMonthsFromTermProperty } from '@kvika/audur-utils';
import { ProductSchema, ProductTerm } from '@kvika/audur-api-types';
import styles from './TermDepositProductSelection.module.scss';
import TextInfo from '../TextInfo';
import { AudurButton } from '../audur-buttons';
import { TranslatedStrings } from '../../data/TranslatedStrings';

type Props = {
  selectedIndex: number;
  termDepositProducts: ProductSchema[];
  onChangeSelection(index: number): void;
  style?: CSSProperties;
};

const TermDepositProductSelection: FunctionComponent<Props> = ({
  selectedIndex,
  termDepositProducts,
  onChangeSelection,
  style,
}) => {
  const { calculatorStrings } = TranslatedStrings.is;
  return (
    <div className={styles.container} style={style}>
      <TextInfo text={calculatorStrings.termSelectionText} style={{ marginBottom: Spacing.Medium }} />
      <div className={styles.buttonGroup}>
        {termDepositProducts.map((product, index) => (
          <div key={product.term} className={index === 1 ? styles.buttonContainerMid : styles.buttonContainer}>
            <AudurButton
              title={toPercentageString(product.interestRate)}
              onClick={() => onChangeSelection(index)}
              style={{
                borderWidth: 2,
                borderColor: selectedIndex === index ? Colors.Transparent : Colors.Orange,
                color: selectedIndex === index ? Colors.Beige : Colors.DarkGray,
                backgroundColor: selectedIndex === index ? Colors.Orange : Colors.Transparent,
                width: '100%',
                height: 40,
              }}
            >
              {toPercentageString(product.interestRate)}
            </AudurButton>
            <TextInfo
              text={`${getMonthsFromTermProperty(
                product.term ?? ProductTerm.Months3
              )} ${calculatorStrings.months.toLowerCase()}`}
              className={styles.buttonSubtitle}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermDepositProductSelection;
