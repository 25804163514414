import React, { CSSProperties } from 'react';
import Image from 'next/image';

import styles from './ImageCard.module.scss';
import { ImageSource } from '../types/Types';

type Props = {
  imageSource: ImageSource;
  style?: CSSProperties;
};

const ImageCard = ({ imageSource, style }: Props) => {
  return (
    <div
      className={styles.imageContainer}
      style={{
        marginBottom: imageSource.imageOffset ? imageSource.imageOffset?.y : 0,
        ...style,
      }}
    >
      <Image
        className={styles.image}
        src={imageSource.src}
        alt={imageSource.alt}
        width={imageSource.width}
        height={imageSource.height}
        priority
      />
    </div>
  );
};

export default ImageCard;
