import React, { CSSProperties, FunctionComponent } from 'react';
import { toIskCurrencyString } from '@kvika/string-utils';
import { getScaledCalcValue } from '@kvika/audur-utils';
import { useMediaQuery } from 'react-responsive';

import styles from './SliderRow.module.scss';
import TextInfo from '../TextInfo';
import Slider from '../slider/Slider';
import CalculatorInput from './CalculatorInput';

type Props = {
  title: string;
  value?: number;
  valueToDisplay?: number;
  valueSuffix?: string;
  longSuffix?: string;
  isCurrency?: boolean;
  min: number;
  max: number;
  step?: number;
  onChangeSlider(event: React.ChangeEvent<HTMLInputElement>): void;
  onChangeInput(newValue?: number): void;
  style?: CSSProperties;
};

const SliderRow: FunctionComponent<Props> = ({
  title,
  value,
  valueToDisplay,
  valueSuffix = 'kr.',
  longSuffix,
  isCurrency = true,
  min,
  max,
  step = 1,
  onChangeSlider,
  onChangeInput,
  style,
}) => {
  const displayValue = (valueToDisplay !== undefined ? valueToDisplay : value) ?? 0;
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });

  return (
    <div className={styles.itemContainer} style={style}>
      <div className={styles.sliderBox}>
        <div className={styles.sliderText}>
          <TextInfo text={title} />
          {isMobile && (
            <TextInfo
              text={
                isCurrency ? toIskCurrencyString(displayValue, true) : `${displayValue} ${longSuffix ?? valueSuffix}`
              }
            />
          )}
        </div>
        <div className={styles.slider}>
          <Slider value={value ?? 0} onChange={onChangeSlider} min={min} max={max} step={step} />
        </div>
      </div>
      <div className={styles.calculatorInputContainer}>
        <CalculatorInput
          value={displayValue}
          suffix={` ${valueSuffix}`}
          onChange={onChangeInput}
          max={valueToDisplay !== undefined ? getScaledCalcValue(max) : max}
        />
      </div>
    </div>
  );
};

export default SliderRow;
