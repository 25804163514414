import React, { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';

import { toIskCurrencyString } from '@kvika/string-utils';
import { getAudurTermDepositInterestText } from '@kvika/audur-strings';
import {
  getAverageMarketTermDepositInterestRate,
  getMarketTermDepositInterestText,
  valueOrDefault,
} from '@kvika/audur-prismic-utils';
import {
  projectedTermDepositAccountEarnings,
  getMonthsFromTermProperty,
  getCalcPosition,
  getScaledCalcValue,
} from '@kvika/audur-utils';
import { AudurMarketInterestRatesTermDepositMarketInterestRates } from '@kvika/audur-prismic-types';
import { ProductSchema, ProductTerm } from '@kvika/audur-api-types';
import styles from './CalculatorStyles.module.scss';
import ProjectedEarnings from './ProjectedEarnings';
import SliderRow from './SliderRow';
import TermDepositProductSelection from './TermDepositProductSelection';
import { GridContainer, GridItem } from '../grid-container';
import { stringToInt } from '../../utils/Utils';
import { TranslatedStrings } from '../../data/TranslatedStrings';

const { calculatorStrings } = TranslatedStrings.is;

type Props = {
  initialBalance?: number;
  products: ProductSchema[];
  termDepositMarketInterestRates: AudurMarketInterestRatesTermDepositMarketInterestRates;
};

const TermDepositCalculator: FunctionComponent<Props> = ({
  initialBalance,
  products,
  termDepositMarketInterestRates,
}) => {
  const sliderStartingBalance = valueOrDefault(initialBalance, 0);
  const [originalBalance, setOriginalBalance] = useState<number | undefined>(sliderStartingBalance);
  const [originalBalancePos, setOriginalBalancePos] = useState<number | undefined>(
    getCalcPosition(sliderStartingBalance)
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [projectedInterest, setProjectedInterest] = useState<string>('');
  const [avgMarketInterest, setAvgMarketInterest] = useState<string>('');
  const [avgMarketInterestRate, setAvgMarketInterestRate] = useState<number>(0);
  const [monthsOfTerm, setMonthsOfTerm] = useState<number>(0);

  const handleChangeBalanceSlider = (event: ChangeEvent<HTMLInputElement>) => {
    const position = stringToInt(event.target.value);
    const balance = getScaledCalcValue(position);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };
  const handleChangeBalanceInput = (balance: number | undefined) => {
    const position = getCalcPosition(balance);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };

  useEffect(() => {
    if (products.length > 0) {
      const selectedProduct = products[selectedIndex];
      const termDepositInterestRate = selectedProduct.interestRate;
      const monthsOfTerm = getMonthsFromTermProperty(selectedProduct.term ?? ProductTerm.Months3);
      const marketInterestRate = getAverageMarketTermDepositInterestRate(monthsOfTerm, termDepositMarketInterestRates);

      const termDepositAccountEarnings = projectedTermDepositAccountEarnings(
        termDepositInterestRate,
        originalBalance ?? 0,
        monthsOfTerm
      );

      const marketTermDepositEarnings = projectedTermDepositAccountEarnings(
        marketInterestRate,
        originalBalance ?? 0,
        monthsOfTerm
      );

      setProjectedInterest(toIskCurrencyString(termDepositAccountEarnings.interest, true));
      setAvgMarketInterest(toIskCurrencyString(marketTermDepositEarnings.interest, true));
      setAvgMarketInterestRate(marketInterestRate);
      setMonthsOfTerm(monthsOfTerm);
    }
  }, [selectedIndex, originalBalance, products, termDepositMarketInterestRates]);

  useEffect(() => {
    if (typeof initialBalance === 'number') {
      setOriginalBalance(initialBalance);
      setOriginalBalancePos(getCalcPosition(initialBalance));
    }
  }, [initialBalance]);

  const clearState = () => {
    setOriginalBalance(10000);
    setOriginalBalancePos(getCalcPosition(10000));
    setSelectedIndex(0);
  };

  return (
    <GridContainer>
      <GridItem className={styles.gridColumnsLeft} style={{ justifyContent: 'flex-start' }}>
        <div style={{ flex: 2 }}>
          <SliderRow
            title={calculatorStrings.originalBalance}
            value={originalBalancePos}
            valueToDisplay={originalBalance}
            valueSuffix="kr."
            min={0}
            max={200}
            step={1}
            onChangeSlider={(event) => handleChangeBalanceSlider(event)}
            onChangeInput={handleChangeBalanceInput}
          />
        </div>
        <TermDepositProductSelection
          style={{ flex: 1 }}
          selectedIndex={selectedIndex}
          termDepositProducts={products}
          onChangeSelection={setSelectedIndex}
        />
      </GridItem>
      <GridItem className={styles.gridColumnsRight} style={{ justifyContent: 'flex-start' }}>
        {products.length > 0 ? (
          <ProjectedEarnings
            selectedProduct={products[selectedIndex]}
            projectedBalance=""
            projectedInterest={projectedInterest}
            averageMarketInterest={avgMarketInterest}
            averageMarketInterestRate={avgMarketInterestRate}
            onClearButtonClick={clearState}
            audurInterestTooltipText={getAudurTermDepositInterestText(
              monthsOfTerm,
              products[selectedIndex].interestRate
            )}
            marketInterestTooltipText={getMarketTermDepositInterestText(monthsOfTerm, termDepositMarketInterestRates)}
          />
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default TermDepositCalculator;
