import { CSSProperties } from 'react';
import { Colors, ColorName } from '@kvika/audur-theme';

import ImageCard from '../ImageCard';
import LinkCard from '../LinkCard';
import { GridItem, MainGridContainer } from '../grid-container';
import { ColumnClass, Gutters } from '../../types/Types';
import { TranslatedStrings } from '../../data/TranslatedStrings';
import { Route } from '../../navigation/Routes';
import styles from './AudurWelcomeContent.module.scss';

type Props = {
  style?: CSSProperties;
};

const AudurWelcomeContent = ({ style }: Props) => {
  const { home } = TranslatedStrings.is;
  return (
    <MainGridContainer
      backgroundColor={Colors.LightOrange}
      gutters={Gutters.Both}
      style={{ paddingBottom: 4, ...style }}
    >
      <>
        <GridItem className={ColumnClass.Left} style={{ justifyContent: 'center' }}>
          <ImageCard
            imageSource={{
              src: '/svg/flower_v2.svg',
              alt: 'flower',
              width: 800,
              height: 1080,
            }}
          />
        </GridItem>
        <GridItem className={ColumnClass.Right} style={{ justifyContent: 'center' }}>
          <LinkCard
            style={{ paddingBottom: 64 }}
            className={styles.heading}
            heading={<div className={ColorName.DarkGray}>{home.helloContent.headline}</div>}
            subHeading={
              <div className={ColorName.DarkGray}>
                <h4>{home.helloContent.subheadline}</h4>
              </div>
            }
            linkSource={{
              href: Route.Savings,
              title: home.helloContent.linkText,
              fillColor: Colors.DarkGray,
            }}
            secondLinkSource={{
              href: Route.AudurApp,
              title: home.mainContent.linkText,
            }}
          />
        </GridItem>
      </>
    </MainGridContainer>
  );
};

export default AudurWelcomeContent;
