import React, { FunctionComponent, useState } from 'react';
import { Colors, Spacing, FontSizeRem } from '@kvika/audur-theme';

import { ClickAwayListener, IconButton, makeStyles, PopperPlacementType, Tooltip } from '@material-ui/core';
import { CloseOutlined, InfoOutlined } from '@material-ui/icons';

type TooltipProps = {
  text: string;
  heading?: string;
  textColor?: string;
  backgroundColor?: string;
  fontSize?: string;
  showArrow?: boolean;
  placement?: PopperPlacementType;
  maxWidth?: string;
};

type TooltipContentProps = {
  text: string;
  heading?: string;
  onClose(): void;
};

const TooltipContent: FunctionComponent<TooltipContentProps> = ({ text, heading, onClose }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex' }}>
        <h4 style={{ flex: 1, marginTop: Spacing.Small, marginBottom: Spacing.Medium, color: Colors.DarkGray }}>
          {heading}
        </h4>
        <IconButton onClick={onClose} style={{ padding: 5, alignSelf: 'flex-start' }}>
          <CloseOutlined />
        </IconButton>
      </div>
      <p style={{ flex: 1, marginTop: 0, whiteSpace: 'pre-wrap', fontFamily: 'CircularPro' }}>{text}</p>
    </div>
  );
};

const useStyles = (textColor?: string, backgroundColor?: string, fontSize?: string, maxWidth?: string) =>
  makeStyles({
    tooltip: {
      backgroundColor: backgroundColor ?? Colors.LightOrange,
      color: textColor ?? Colors.DarkGray,
      fontSize: fontSize ?? FontSizeRem.Body,
      fontFamily: 'CircularPro',
      textTransform: 'none',
      maxWidth: maxWidth ?? '300px',
      marginRight: Spacing.Small,
      padding: Spacing.Medium,
    },
    arrow: {
      '&:before': {
        backgroundColor: backgroundColor ?? Colors.LightOrange,
      },
    },
  });

const AudurTooltip: FunctionComponent<TooltipProps> = ({
  text,
  heading,
  textColor,
  backgroundColor,
  fontSize,
  showArrow,
  placement,
  maxWidth,
}) => {
  const classes = useStyles(textColor, backgroundColor, fontSize, maxWidth)();
  const [isHoverOpen, setIsHoverOpen] = useState(false);
  const [isClickOpen, setIsClickOpen] = useState(false);

  const handleTooltipHoverClose = () => {
    setIsHoverOpen(false);
  };

  const handleTooltipClickClose = () => {
    setIsClickOpen(false);
    setIsHoverOpen(false);
  };

  const handleTooltipHoverOpen = () => {
    setIsHoverOpen(true);
  };

  const handleTooltipClickOpen = () => {
    setIsClickOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClickClose}>
      <div>
        <Tooltip
          title={<TooltipContent text={text} heading={heading} onClose={handleTooltipClickClose} />}
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          PopperProps={{ disablePortal: true }}
          open={isHoverOpen || isClickOpen}
          onClose={handleTooltipHoverClose}
          placement={placement ?? 'bottom-end'}
          interactive
          arrow={showArrow}
          enterTouchDelay={0}
        >
          <IconButton
            onClick={handleTooltipClickOpen}
            onMouseOver={handleTooltipHoverOpen}
            onTouchStart={handleTooltipClickOpen}
            style={{ padding: 4 }}
          >
            <InfoOutlined style={{ color: Colors.LightOrange }} />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default AudurTooltip;
