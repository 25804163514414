import React, { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';

import { toIskCurrencyString } from '@kvika/string-utils';
import { getAudurSavingsAccountInterestText } from '@kvika/audur-strings';
import {
  getAverageMarketSavingsInterestRate,
  getAurbjorgSavingsAccountMarketInterestText,
} from '@kvika/audur-prismic-utils';
import { projectedSavingsAccountEarnings, getScaledCalcValue, getCalcPosition } from '@kvika/audur-utils';
import { AudurMarketInterestRatesSavingsMarketInterestRates } from '@kvika/audur-prismic-types';

import { ProductSchema } from '@kvika/audur-api-types';
import SliderRow from './SliderRow';
import ProjectedEarnings from './ProjectedEarnings';
import styles from './CalculatorStyles.module.scss';
import { stringToInt } from '../../utils/Utils';
import { GridContainer, GridItem } from '../grid-container';
import { TranslatedStrings } from '../../data/TranslatedStrings';

const { calculatorStrings } = TranslatedStrings.is;

type Props = {
  initialBalance?: number;
  product: ProductSchema;
  savingsMarketInterestRates: AudurMarketInterestRatesSavingsMarketInterestRates;
};

const SavingsCalculator: FunctionComponent<Props> = ({ initialBalance, product, savingsMarketInterestRates }) => {
  const [originalBalance, setOriginalBalance] = useState<number | undefined>(initialBalance ?? 0);
  const [originalBalancePos, setOriginalBalancePos] = useState<number | undefined>(0);
  const [monthlyDeposit, setMonthlyDeposit] = useState<number | undefined>(0);
  const [months, setMonths] = useState<number | undefined>(12);
  const [projectedBalance, setProjectedBalance] = useState<string>('');
  const [projectedInterest, setProjectedInterest] = useState<string>('');
  const [avgMarketInterest, setAvgMarketInterest] = useState<string>('');

  const handleChangeBalanceSlider = (event: ChangeEvent<HTMLInputElement>) => {
    const position = stringToInt(event.target.value);
    const balance = getScaledCalcValue(position);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };
  const handleChangeBalanceInput = (balance: number | undefined) => {
    const position = getCalcPosition(balance);
    setOriginalBalancePos(position);
    setOriginalBalance(balance);
  };
  const handleChangeMonthlyDeposit = (event: ChangeEvent<HTMLInputElement>) => {
    setMonthlyDeposit(stringToInt(event.target.value));
  };
  const handleChangeMonths = (event: ChangeEvent<HTMLInputElement>) => {
    setMonths(stringToInt(event.target.value));
  };

  const avgMarketInterestRate = getAverageMarketSavingsInterestRate(originalBalance ?? 0, savingsMarketInterestRates);

  useEffect(() => {
    const projectedEarnings = projectedSavingsAccountEarnings(
      product.interestRate,
      originalBalance ?? 0,
      months ?? 0,
      monthlyDeposit ?? 0
    );

    const projectedAvgMarketEarnings = projectedSavingsAccountEarnings(
      avgMarketInterestRate,
      originalBalance ?? 0,
      months ?? 0,
      monthlyDeposit ?? 0
    );

    setProjectedBalance(toIskCurrencyString(projectedEarnings.balance, true));
    setProjectedInterest(toIskCurrencyString(projectedEarnings.interest, true));
    setAvgMarketInterest(toIskCurrencyString(projectedAvgMarketEarnings.interest, true));
  }, [originalBalance, monthlyDeposit, months, product?.interestRate, avgMarketInterestRate]);

  useEffect(() => {
    if (typeof initialBalance === 'number') {
      setOriginalBalance(initialBalance);
      setOriginalBalancePos(getCalcPosition(initialBalance));
    }
  }, [initialBalance]);

  const monthString = months === 1 ? calculatorStrings.month : calculatorStrings.months;

  const clearState = () => {
    setOriginalBalance(10000);
    setOriginalBalancePos(getCalcPosition(10000));
    setMonthlyDeposit(0);
    setMonths(12);
  };

  return (
    <GridContainer>
      <GridItem className={styles.gridColumnsLeft} style={{ justifyContent: 'flex-start' }}>
        <SliderRow
          title={calculatorStrings.originalBalance}
          value={originalBalancePos}
          valueToDisplay={originalBalance}
          valueSuffix="kr."
          min={0}
          max={200}
          step={1}
          onChangeSlider={(event) => handleChangeBalanceSlider(event)}
          onChangeInput={handleChangeBalanceInput}
        />
        <SliderRow
          title={calculatorStrings.monthlyDeposit}
          value={monthlyDeposit}
          valueSuffix="kr."
          min={0}
          max={1000000}
          step={500}
          onChangeSlider={(event) => handleChangeMonthlyDeposit(event)}
          onChangeInput={setMonthlyDeposit}
        />
        <SliderRow
          title={calculatorStrings.time}
          value={months}
          valueSuffix={`${monthString}`}
          isCurrency={false}
          min={1}
          max={40}
          onChangeSlider={(event) => handleChangeMonths(event)}
          onChangeInput={setMonths}
        />
      </GridItem>
      <GridItem className={styles.gridColumnsRight} style={{ justifyContent: 'flex-start' }}>
        {product ? (
          <ProjectedEarnings
            selectedProduct={product}
            projectedBalance={projectedBalance}
            projectedInterest={projectedInterest}
            averageMarketInterest={avgMarketInterest}
            averageMarketInterestRate={avgMarketInterestRate}
            onClearButtonClick={clearState}
            audurInterestTooltipText={getAudurSavingsAccountInterestText(product.interestRate)}
            marketInterestTooltipText={getAurbjorgSavingsAccountMarketInterestText(savingsMarketInterestRates)}
          />
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

export default SavingsCalculator;
