import { motion } from 'framer-motion';

type Props = {
  className?: string;
  children?: JSX.Element | string;
  isVisible: boolean;
  xPos: number;
};

const MotionContainer = ({ children, className, isVisible, xPos }: Props) => {
  return (
    <motion.div
      animate={isVisible ? 'visible' : 'hidden'}
      variants={{ hidden: { x: xPos }, visible: { x: 0 } }}
      transition={{ ease: 'easeInOut' }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default MotionContainer;
