import { CSSProperties } from 'react';
import { Colors } from '@kvika/audur-theme';
import AudurButtonLink from './audur-buttons/AudurButtonLink';
import styles from './LinkCard.module.scss';
import { LinkSource } from '../types/Types';

type Props = {
  heading?: JSX.Element;
  subHeading?: JSX.Element;
  linkSource: LinkSource;
  secondLinkSource?: LinkSource;
  className?: string;
  centerLinkOnMobile?: boolean;
  style?: CSSProperties;
};

const LinkCard = ({
  linkSource,
  secondLinkSource,
  className,
  centerLinkOnMobile = false,
  style,
  heading,
  subHeading,
}: Props) => {
  const { fillColor } = linkSource;
  const secondaryFillColor = secondLinkSource?.fillColor;
  const linkContainerStyle = centerLinkOnMobile
    ? `${styles.buttonContainer} ${styles.mobileCenter}`
    : `${styles.buttonContainer}`;
  return (
    <div className={`${styles.container} ${className}`} style={style}>
      {heading && heading}
      {subHeading && subHeading}
      <div className={linkContainerStyle}>
        <AudurButtonLink
          style={{ width: 180, ...linkSource.style }}
          href={linkSource.href}
          fillColor={fillColor ?? Colors.Red}
          openInNewTab={Boolean(linkSource.openInNewTab)}
        >
          {linkSource.title}
        </AudurButtonLink>
        {secondLinkSource && (
          <AudurButtonLink
            style={{ width: 180, marginLeft: 24, ...secondLinkSource.style }}
            href={secondLinkSource.href}
            fillColor={secondaryFillColor ?? Colors.Red}
            openInNewTab={Boolean(secondLinkSource.openInNewTab)}
          >
            {secondLinkSource.title}
          </AudurButtonLink>
        )}
      </div>
    </div>
  );
};

export default LinkCard;
