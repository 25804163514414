import { Colors } from '@kvika/audur-theme';
import React, { CSSProperties } from 'react';

import styles from './Slider.module.scss';

type NativeInputProps = React.InputHTMLAttributes<HTMLInputElement>;
type Props = {
  min: NativeInputProps['min'];
  max: NativeInputProps['max'];
  value?: NativeInputProps['value'];
  defaultValue?: NativeInputProps['defaultValue'];
  onChange?: NativeInputProps['onChange'];
  onBlur?: NativeInputProps['onBlur'];
  step?: NativeInputProps['step'];
  trackColor?: Colors;
  style?: CSSProperties;
};

const Slider: React.VFC<Props> = ({
  min,
  max,
  value,
  defaultValue,
  onChange,
  onBlur,
  step,
  trackColor = Colors.LightOrange,
  style,
}) => {
  return (
    <div className={styles.container}>
      <input
        type="range"
        min={min}
        max={max}
        defaultValue={defaultValue}
        className={styles.slider}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        step={step}
        style={{ backgroundColor: trackColor, ...style }}
      />
    </div>
  );
};
export default Slider;
