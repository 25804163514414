import React, { CSSProperties, FunctionComponent } from 'react';

import { Colors } from '@kvika/audur-theme';

type Props = {
  text: string;
  fontSize?: string;
  bold?: boolean;
  color?: string;
  style?: CSSProperties;
  className?: string;
};

const TextInfo: FunctionComponent<Props> = ({ text, fontSize, bold, color, style, className }) => {
  return (
    <p
      style={{
        margin: 0,
        fontSize,
        color: color || Colors.DarkGray,
        fontFamily: 'CircularPro',
        fontWeight: bold ? 'bold' : 'normal',
        ...style,
      }}
      className={className}
    >
      {text}
    </p>
  );
};

export default TextInfo;
